<template>
  <layout name="OrganizLayout">
    <div class="Home">
      <page-header/>
      <!-- HERO -->
      <section style="margin-bottom: 50px">
        <div class="uk-container " style="min-height: 600px;">
          <div class="uk-text-center uk-margin">
            <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
              <li>
                <a href>{{ $t("organization.stuList.home") }}</a>
              </li>
              <li>
                <span>{{ $t("organization.stuList.pList") }}</span>
              </li>
            </ul>
            <!--<h1 class="uk-margin-small-top uk-margin-remove-bottom">Student register</h1>-->
          </div>
          <ul uk-tab>
            <li class="uk-active"><a href="#">
              {{ $t("students") }}
            </a></li>
            <li><a href="#">{{ $t("stuGroup") }}</a></li>
          </ul>

          <ul class="uk-switcher uk-margin">
            <li>
              <div class="uk-width-1-1 uk-width-expand@m">
                <div class="uk-grid uk-grid-small uk-margin-medium-bottom uk-margin-medium-top"
                     uk-margin>
                  <div class="uk-inline">
                    <input v-model="name" :placeholder="$t('organization.stuList.name')"
                           class="uk-input ukInput" type="text">
                  </div>
                  <div class="uk-inline">
                    <input v-model="email" :placeholder="$t('organization.stuList.email')"
                           class="uk-input ukInput"
                           type="email">
                  </div>
                  <div class="uk-inline">
                    <date-picker
                        class="date-picker"
                        v-model="sdate"
                        valueType="format"
                        :lang="lang"
                        :placeholder="$t('startDate')"
                    ></date-picker>
                  </div>
                  <div class="uk-inline">
                    <date-picker
                        class="date-picker "
                        v-model="edate"
                        valueType="format"
                        :lang="lang"
                        :placeholder="$t('endDate')"
                    ></date-picker>
                  </div>
                  <div>
                    <button type="button" @click="loaddata(1)" class="uk-button
                            uk-button-default qbtn_d">{{ $t("orgaExam.examResultView.default") }}
                    </button>
                    <button type="button" @click="searchDate" class="uk-button
                            uk-button-primary qbtn_d uk-margin-small-left">{{ $t("organization.stuList.search") }}
                    </button>
                  </div>
                </div>
                <div class="uk-card uk-card-default uk-card-small tm-ignore-container">
                  <header class="uk-card-header"
                          style="border-top: 3px solid rgb(1, 176, 255) !important;">
                    <div class="uk-grid-small uk-flex-middle" uk-grid>
                      <div class="uk-width-1-1 uk-width-expand@s uk-flex
                        uk-flex-center uk-flex-left@s uk-text-small">
                        <span style="margin-right:10px" class="uk-flex uk-flex-middle">
                          {{ $t("organization.stuList.sort") }}:</span>
                        <div uk-form-custom="target: > * > span:first-child">
                          <select v-model="sortselected" @change="onChangeSort()"
                                  class="sortBy">
                            <option value="norm">{{ $t("organization.stuList.normal") }}
                            </option>
                            <option value="name">{{ $t("organization.stuList.name") }}
                            </option>
                            <option value="email">{{ $t("organization.stuList.email") }}
                            </option>
                            <option value="enabled">{{ $t("organization.stuList.enabled") }}
                            </option>
                          </select>
                          <button type="button" class="uk-button uk-button-default btn-sortBy"
                                  tabindex="-1">
                            <span uk-icon="icon: chevron-down"></span>
                          </button>
                        </div>
                      </div>

                      <div class="uk-width-1-1 uk-width-auto@s uk-flex uk-flex-center uk-flex-middle">
                        <router-link
                            class="uk-button uk-button-default uk-button-small uk-hidden@m"
                            style="color: #000000" title="Student register" tag="a"
                            :to="{name: 'studentregister', params:{ id: 0}}"><span
                            class="uk-margin-xsmall-right"
                            uk-icon="icon: plus; ratio: .75;">
                    </span>{{ $t("organization.stuList.cStub") }}
                        </router-link>
                        <h5 class="uk-margin-remove uk-padding-right">Student request : </h5>
                        <router-link
                            class="uk-button uk-button-primary uk-button-small uk-margin-small-left"
                            title="Student new requests" tag="a"
                            :to="{name: 'studentrequest'}">
<!--                           class="uk-badge"-->
                          <span>{{ newCount }}</span>
                        </router-link>
                        <div class="tm-change-view uk-margin-small-left">
                          <ul class="uk-subnav uk-iconnav js-change-view">
                            <li>
                              <router-link
                                  class="uk-button uk-button-default uk-button-small"
                                  style="color: rgb(1, 176, 255); border-color: rgb(1, 176, 255)"
                                  title="Student register" tag="a"
                                  :to="{name: 'studentregister', params:{ id: 0}}"><span
                                  class="uk-margin-xsmall-right"
                                  uk-icon="icon: plus; ratio: .95;"
                              ></span>{{ $t("organization.stuList.cStub") }}
                              </router-link>
<!--                              <router-link-->
<!--                                  class="uk-button uk-button-default uk-button-small"-->
<!--                                  style="color: rgb(1, 176, 255); border-color: rgb(1, 176, 255)"-->
<!--                                  title="Student new requests" tag="a"-->
<!--                                  :to="{name: 'studentrequest'}">-->
<!--                                New request : <span class="uk-badge">{{ newCount }}</span>-->
<!--                              </router-link>-->
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </header>
                  <div class="uk-card-body">
                    <table v-if="students.length > 0" id="example"
                           class="uk-table uk-table-hover uk-table-striped uk-table-responsive"
                           style="width:100%">
                      <thead>
                      <tr>
                        <th>No</th>
                        <th>{{ $t("image") }}</th>
                        <th>{{ $t("organization.stuList.fName") }}</th>
                        <th>{{ $t("department") }}</th>
                        <th>{{ $t("ID") }}</th>
                        <th>{{ $t("organization.stuList.email") }}</th>
                        <th>{{ $t("organization.stuList.enabled") }}</th>
                        <th>{{ $t("organization.stuList.created") }}</th>
                        <th>{{ $t("organization.stuList.actions") }}</th>
                      </tr>
                      </thead>
                      <tbody v-if="students.length > 0">

                      <tr v-for="(item,index) in students"
                          :item="item"
                          :key="index">
                        <td>
                          <span v-if="currentPage == 0">
                            {{ index + 1 }}
                          </span>
                                <span v-else>
                            {{ ((currentPage * size) + index) - size + 1 }}
                          </span>
                        </td>
                        <td>
                          <div v-if="item.image != null">
                            <img  :src="`/uploadingDir/${item.image}`" width="50"
                                  :alt="item.image"/>
                          </div>
                          <img v-else width="45" src="../../assets/user_pro.png"/>
                        </td>
                        <td>{{ item.firstName }}</td>
                        <td>{{ item.department }}</td>
                        <td>{{ item.username }}</td>
                        <td>{{ item.email }}</td>
                        <td><span v-if="item.enabled"
                                  class="uk-label uk-label-success">Success</span>
                          <span v-else class="uk-label uk-label-warning">Waiting</span>
                        </td>
                        <td>{{ item.createdAt | moment }}</td>
                        <td>
                          <router-link title="Student register" tag="a"
                                       :to="{name: 'studentregister', params: {id: item.id}}">
                            <span uk-icon="icon: file-edit; ratio: 1.5;"></span>
                          </router-link>
                          <a class="uk-text-danger" @click="showDelete(item)"><span
                              uk-icon="icon: trash; ratio: 1.5;"></span></a>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div v-else class="uk-alert-primary " uk-alert>
                      <p class="uk-text-center">{{ $t("organization.stuList.notFound") }}</p>
                    </div>
                  </div>
                  <div class="uk-card-footer uk-text-center">
                    <div class="uk-flex uk-flex-center">
                      <v-pagination v-model="currentPage"
                                    :page-count="pages"
                                    :classes="uikitClasses"
                                    :labels="Labels"
                                    @change="onChange">
                      </v-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <group-data></group-data>
            </li>
          </ul>
        </div>
        <div id="deleteModal" uk-modal>
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default"  uk-close></button>
            <div class="uk-modal-header">
              <h2 class="uk-modal-title uk-text-center"
                  style="font-size: 21px; font-weight: 700;">{{$t("duser")}}</h2>
            </div>
            <div class="uk-modal-body uk-text-left">
              <h3>{{$t('userAskQu')}}</h3>
              <h3>{{$t('username')}} : <span class="uk-text-primary uk-text-bold uk-margin-left"> {{username}}</span></h3>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close" >{{$t('no')}}
              </button>
              <button type="button" class="uk-button uk-button-primary uk-button-small uk-margin-left"
                      @click="deleteUser()"> {{$t('yes')}}
              </button>
            </div>
          </div>
        </div>
      </section>
      <page-footer/>
    </div>
  </layout>
</template>

<script scoped>
import Layout from '../../layouts/Layout'
import UserService from '@/services/UserService'
import moment from 'moment'
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import vPagination from '@/components/globals/vue-plain-pagination'
import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
import GroupData from '@/components/Organizcmp/OrganizStGroupList.vue'

const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")
import {mapGetters} from 'vuex'
import UIkit from "uikit";

export default {
  name: 'Home',
  components: {
    Layout,
    vPagination,
    DatePicker,
    PageHeader,
    PageFooter,
    GroupData
  },
  data() {
    return {
      newCount: 0,
      sdate: null,
      edate: null,
      name: null,
      email: null,
      error: null,
      username: '',
      totalElements: '',
      sortselected: 'norm',
      students: [],
      currentPage: 1,
      size: 12,
      pages: 0,
      uikitClasses: {
        ul: 'uk-pagination',
        li: '',
        liActive: 'uk-active',
        liDisable: 'uk-disabled',
        button: 'page-link'
      },
      Labels: {
        first: 'First',
        prev: '<span uk-pagination-previous></span>',
        next: '<span uk-pagination-next></span>',
        last: "Last"
      },
      lang: {
        days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ],
        placeholder: {
          dateRange: "Start date ~ End date"
        }
      },
      shortcuts: [
        {
          text: "Today",
          onClick: () => {
            this.time3 = [new Date(), new Date()];
          }
        }
      ],
      excel: {
        excelFile: null,
        fileName: "",
      },
      timePickerOptions: {
        start: "00:00",
        step: "00:30",
        end: "23:30"
      },
    }
  },

  filters: {
    moment: function (date) {
      return moment(date).format('YYYY.MM.DD')
      // return moment(date).format('YYYY-MM-DD, h:mm:ss a')
    }
  },
  mounted() {
    this.newRequestsCount();
  },
  methods: {
    onChangeSort() {
      this.currentPage = 1
      this.loaddata(this.currentPage)
    },
    searchDate() {
      this.currentPage = 1
      this.loaddata(this.currentPage)
    },
    showDelete(item){
      this.userDeleteId = item.id
      this.username = item.firstName
      UIkit.modal('#deleteModal').show();
    },
    async deleteUser() {
      try {
        const response = await UserService.userDelete({
          organizId: this.currentUser.organizs[0].organizId,
          userId: this.userDeleteId
        })
        if (response.data.status == 200) {
          alert(response.data.message)
          UIkit.modal('#deleteModal').hide();
          this.loaddata()
        }

      } catch (error) {
        console.log(error)
      }
    },
    async loaddata(page) {
      try {

        if (page > 0) {
          page = page - 1
        }
        const response = await UserService.getUsersByPage({
          name: this.name,
          email: this.email,
          sdate: this.sdate,
          edate: this.edate,
          page: page,
          role: 'student',
          sort: this.sortselected,
          organizId: this.currentUser.organizs[0].organizId

        });
        console.log(response)
        if (response.status == 200) {
          this.students = response.data.content;
          // this.currentPage = response.data.page;
          this.pages = response.data.totalPages;
          this.totalElements = response.data.totalElements;
          if (!this.students.length) {
            this.isLoading = false
              return
          }
        }
      } catch (error) {
        this.error = error.response.data.message;
        this.scrollToTop()
      }
    },
   async newRequestsCount() {
      try {
        const response = await UserService.getNewRequestCount({
          organizId: this.currentUser.organizs[0].organizId
        });
        if (response.status == 200) {
          this.newCount = response.data.result.newRequest
        }

      } catch (error) {
        this.error = error.response.data.message;
        this.scrollToTop()
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onChange: function () {
      console.log(`"currentPage" has been changed`)
      this.loaddata(this.currentPage)
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'currentUser'])
  }
}
</script>

<style scoped>
.sortBy {
  border: 1px solid #dee;
  border-radius: 15px;
  padding-left: 12px;
  outline: none;
  opacity: 0;
}
input[type="ssss"]
{
  -webkit-text-security: disc;
}
.btn-sortBy {
  border: 1px solid #dee;
  border-radius: 15px;
  outline: none;
  text-transform: capitalize;
}

.uk-pagination > li > a {
  font-size: 18px !important;
}

.qbtn_d {
  width: 120px;
  background-color: #01b0ff;
  border: 1px solid #fff;
  display: inline-block;
  padding: 0px 10px;
  color: #fff;
  font-weight: 500;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  min-width: 30px;
  text-align: center;
  text-transform: capitalize;
}
input{
  height: 40px;
}
</style>
